@keyframes enter {
  from {
    opacity: 0;
    filter: brightness(2)
  }
  to {
    opacity: 1;
    filter: brightness(1)
  }
}
.App {
  height: 100%;
}
.start {
  background-color: rgb(8, 136, 156);
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: -1;
}
.start::before {
  content: "";
  background: transparent url('./img/giovannimesquita.jpg') center no-repeat;
  background-size: cover;
  height: 100%;
  opacity: 0.7;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  mix-blend-mode: overlay;
}
section {
  padding: calc(2em + 0.5%) 2em 2em calc(3.5em + 1%);
  animation: enter 0.6s ease-in;
  height: 100%;
  overflow: auto;
}

@media screen and (orientation: landscape) {
  body {
    font-size: 19px;
  }
  section {
      padding-right: 28%;
  }
  article img {
    width: 70%;
  }
}

@media (min-width: 1200px) and (orientation: landscape) {
  section {
      padding-right: calc(60% + -20em);
  }
}
header {
  filter: brightness(0.45) sepia(1) hue-rotate(300deg) saturate(4) drop-shadow(0 0 20px rgba(255,255,255,0.5));
}
h1 {
  margin: 1.75em 0 0.75em 0 ;
  font-size: 1.1em;
  font-weight: 600;
  color: #87C0C7;
}
h2 {
  margin: 0 0 0.75em 0;
  font-weight: 300;
  font-size: 2.5em;
  color: #00758E;
  line-height: 1;
}
h3 {
  font-size: 1.15em;
  font-weight: 800;
  color: #8D8D8D;
  margin: 2.5em 0 0 0;
}
h4 {
  color: #ED4866;
  font-weight: 700;
  font-size: 1em;
  margin: 1em 0 1.5em 0 ;
}
a {
  text-decoration: none;
  font-weight: 600;
}
p {
  line-height: 1.5em;
  margin: 0.75em 0 0 0;
}
b {
  color: #333;
}
ul {
  padding: 0 0 0 1em;
}
li {
  margin: 1.25em 0 0 0;
  line-height: 1.35em;
}
article img {
  width: 100%;
  border-radius: 0.2em;
}
figure {
  padding: 0;
  margin: 1em 0 0.75em 0;
}
figcaption {
  margin: 0.75em 0 2.5em 0;
  color: #f6427b;
  font-weight: 600;
}
details {
  margin: 2em 0;
  overflow: hidden;
}
details:not[open] summary ~ ul>* {
  animation: sweep .3s ease-in reverse;
}
details[open] summary ~ ul>* {
  animation: sweep .3s ease-in;
}
@keyframes sweep {
  0%    {opacity: 0; margin-top: -1em; max-height: 0.5em; overflow: hidden;}
  50%  {opacity: 0.9; margin-top: -1em; max-height: 10em; overflow: hidden;}
  100%  {opacity: 1; margin-top: 0em; max-height: auto; overflow: unset;}
}
summary {
  color: #00758E;
  font-weight: 500;
}
summary:focus {
  outline: none;
}
li em {
  display: block;
  font-weight: 600;
  color: #999;
  margin: 0.5em 0 2em 0;
}
figure.perfil {
  width: calc(10% + 5em);
  border-radius: 50%;
  overflow: hidden;
}
figure.perfil img {
  display: block;
  width: 100%;
}
figure.embed div {
  position: relative; 
  padding-bottom: 56.25%; 
  height: 0; 
  overflow: hidden;
}
figure.embed iframe,
figure.embed object,
figure.embed embed { 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
}