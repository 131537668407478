menu {
    background-color: rgba(237,72,102,0.84);
    color: rgba(255,255,255,0.8);
    margin: 0;
    padding: 0;
    position: absolute;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    width: 50%;
    transition: all 0.3s;
}
menu.recolhido {
    width: 0.5em;
    transition: all 0.3s;
}
menu>div {
    width: calc(1em + 60%);
    display: flex;
    flex-flow: column;
    margin: 1em;
    max-width: 8em;
}
menu.recolhido>div {
    width: 0;
    transition: all 0.3s;
}
menu .puxador {
    position: absolute;
    left: 100%;
    top: 10%;
    width: 0;
    transition: all 0.3s;
}
menu.recolhido .puxador {
    top: 1%;
    width: 2rem;
    transition: all 0.3s;
}
menu .frase {
    margin: 0 0 10% 0;
    opacity: 0.7;
    transition: all .5s;
    width: 100%;
}
menu .logo {
    width: 100%;
}
menu nav {
    margin: auto 0 0.5em 0;
    height: 30%;
}
menu nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
}
menu li {
    height: calc(20% + 0.5em);
    min-height: 1em;
}
@keyframes shine {
    0%    {opacity: 0; color: rgba(255, 255, 255, 0); filter: drop-shadow(0 0 0em #fff)}
    50%  {opacity: 1; color: rgba(255, 255, 255, 1); filter: drop-shadow(0 0 1em #fff);}
    100%  {opacity: 0.8; color: rgba(255, 255, 255, 0.8); filter: drop-shadow(0 0 0em #fff);}
}
menu nav a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0);
    font-weight: 600;
    display: flex;
    align-items: center;
    height: 100%;
    overflow: hidden;
    animation: shine 0.8s linear forwards;
}
menu nav li:nth-child(1) a {
    animation-delay: 1s;
}
menu nav li:nth-child(2) a {
    animation-delay: 1.4s;
}
menu nav li:nth-child(3) a {
    animation-delay: 1.8s;
}
menu nav li:nth-child(4) a {
    animation-delay: 2.2s;
}
menu nav li:nth-child(5) a {
    animation-delay: 2.6s;
}
menu .social {
    margin: calc(20% + 0.5em) 0 5% 0;
    display: flex;
    justify-content: left;
    opacity: 0;
    animation: shine 0.5s 3s linear forwards;
}
menu .social a {
    height: 2.3em;
    width: 2.3em;
    border: none;
    background: transparent no-repeat center / contain;
    margin: 0 1.5em 0 0;
}
menu .social a:last-child {
    margin: 0;
}
menu .social .instagram {
    background-image: url('./instagram-ico.svg')
}
menu .social .linkedin {
    background-image: url('./linkedin-ico.svg')
}
menu .social .whatsapp {
    background-image: url('./whatsapp-ico.svg')
}
menu .social span {
    display: none;
}
@media screen and (orientation: landscape) {
    menu .frase {
        position: fixed;
        opacity: 1;
        right: 1.4em;
        filter: brightness(0.35) sepia(1) hue-rotate(290deg) saturate(5) drop-shadow(0 0 20px rgba(255,255,255,0.5));
        background: url('./ux-service-design-interactive.svg') no-repeat center/ contain;
        max-height: 40%;
        width: 16%;
        transition: all .5s;
        color: #dd3a58;
    }
}
@media screen and (min-width: 960px) {
    menu {
        font-size: 1.2em;
    }
}
@media screen and (min-width: 1180px) {
    menu {
        font-size: 1.4em;
    }
}
